export enum Fonts {
  BARLOW = 'Barlow, digando-fallback',
  BARLOW_CONDENSED = '\'Barlow Condensed\', digando-fallback',
}

export enum FontBarlowWeights {
  REGULAR = 400,
  SEMI_BOLD = 600,
}

export enum FontBarlowStyle {
  REGULAR_NORMAL = 'normal',
  SEMI_BOLD_NORMAL = 'normal',
}

export enum FontBarlowCondensedWeights {
  LIGHT= 300,
  REGULAR = 400,
  SEMI_BOLD = 600,
}

export enum FontBarlowCondensedStyle {
  LIGHT_NORMAL = 'normal',
  REGULAR_NORMAL = 'normal',
  REGULAR_ITALIC = 'italic',
  SEMI_BOLD_NORMAL = 'normal',
}

export const BarlowRegular = `font-family: ${Fonts.BARLOW};font-weight: ${FontBarlowWeights.REGULAR};font-style: ${FontBarlowStyle.REGULAR_NORMAL};`;

export const BarlowSemiBold = `font-family: ${Fonts.BARLOW};font-weight: ${FontBarlowWeights.SEMI_BOLD};font-style: ${FontBarlowStyle.SEMI_BOLD_NORMAL};`;

export const BarlowCondensedLight = `font-family: ${Fonts.BARLOW_CONDENSED};font-weight: ${FontBarlowCondensedWeights.LIGHT};font-style: ${FontBarlowCondensedStyle.LIGHT_NORMAL};`;

export const BarlowCondensedRegular = `font-family: ${Fonts.BARLOW_CONDENSED};font-weight: ${FontBarlowCondensedWeights.REGULAR};font-style: ${FontBarlowCondensedStyle.REGULAR_NORMAL};`;

export const BarlowCondensedRegularItalic = `font-family: ${Fonts.BARLOW_CONDENSED};font-weight: ${FontBarlowCondensedWeights.REGULAR};font-style: ${FontBarlowCondensedStyle.REGULAR_ITALIC};`;

export const BarlowCondensedSemiBold = `font-family: ${Fonts.BARLOW_CONDENSED};font-weight: ${FontBarlowCondensedWeights.SEMI_BOLD};font-style: ${FontBarlowCondensedStyle.SEMI_BOLD_NORMAL};`;
