import React, { FC } from 'react';
import { Switch as AntdSwitch, SwitchProps } from 'antd';
import styled from '@emotion/styled';

export const Switch: FC<SwitchProps> = props => <StyledAntdSwitch {...props}>{props}</StyledAntdSwitch>;

Switch.defaultProps = {};

const StyledAntdSwitch = styled(AntdSwitch)`
  display: block;
  height: 25px;
  align-items: center;
  margin-left: 15px;

  // Somehow the switch is not centered vertically
  .ant-switch-inner-unchecked {
    padding-top: 3px;
  }
`;
