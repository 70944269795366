import React from 'react';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Layout, LayoutProps } from 'antd';

interface IHeaderProps {
  collapsed: boolean;
  onToggleCollapse: Function;
}

export const Header: React.FC<IHeaderProps & LayoutProps> = ({collapsed, onToggleCollapse}) => {
  return (
    <StyledHeader>
      {React.createElement(
        collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
        { onClick: () => onToggleCollapse() },
      )}
    </StyledHeader>
  );
};

const StyledHeader = styled(Layout.Header)`
  background-color: #ffffff;
`;
