const spacingWhiteList: number[] = [0, 0.5, 1, 1.5, 2, 3, 4, 5, 6, 7, 8, 10, 14];

export const spacingCalculator = (multiplier: number): string => {
  if ('development' === process.env.NODE_ENV && -1 === spacingWhiteList.indexOf(multiplier)) {
    throw new Error(`Only spacing with sizes "${spacingWhiteList.join(', ')}" are allowed.`);
  }

  return `calc(${multiplier} * var(--spacing-steps))`;
};

export const spacingTop = (multiplier = 1): string => {
  return `
     padding-top: ${spacingCalculator(multiplier)};
  `;
};

export const spacingBottom = (multiplier = 1): string => {
  return `
     padding-bottom: ${spacingCalculator(multiplier)};
  `;
};

export const spacingLeft = (multiplier = 1): string => {
  return `
     padding-left: ${spacingCalculator(multiplier)};
  `;
};

export const spacingRight = (multiplier = 1): string => {
  return `
     padding-right: ${spacingCalculator(multiplier)};
  `;
};

export const spacingTopBottom = (multiplier = 1): string => {
  return `
     ${spacingTop(multiplier)};
     ${spacingBottom(multiplier)};
  `;
};

export const spacingLeftRight = (multiplier = 1): string => {
  return `
     ${spacingLeft(multiplier)};
     ${spacingRight(multiplier)};
  `;
};

export const spacingAround = (multiplier = 1): string => {
  return `
     ${spacingTop(multiplier)};
     ${spacingBottom(multiplier)};
     ${spacingLeft(multiplier)};
     ${spacingRight(multiplier)};
  `;
};
