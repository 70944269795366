import React from 'react';
import styled from '@emotion/styled';
import { Layout as AntdLayout } from 'antd';

import { MainMenu } from './MainMenu';
import { Header } from './Header';
import { Content } from './Content';
import paths from '../../configs/routes';

const { Sider: AntdSider } = AntdLayout;

interface IStateProps {
  children?: React.ReactNode;
  title?: string;
  icon?: React.ReactNode;
}

export const BasicLayout: React.FC<IStateProps> = ({ children }) => {
  const [menuCollapsed, setMenuCollapsed] = React.useState(true);

  const handleToggleCollapse = (): void => {
    setMenuCollapsed(!menuCollapsed);
  };

  return (
    <Layout>
      <Sider
        trigger={null}
        collapsible
        collapsed={menuCollapsed}
      >
        <MainMenu
          routes={paths}
          collapsed={menuCollapsed}
        />
      </Sider>
      <AntdLayout>
        <Header
          collapsed={menuCollapsed}
          onToggleCollapse={handleToggleCollapse}
        />
        <Content>
          {children}
        </Content>
      </AntdLayout>
    </Layout>
  );
};

const Layout = styled(AntdLayout)`
  min-height: 100vh;
`;

const Sider = styled(AntdSider)`
  min-height: 100vh;
  height: 100%;
`;
