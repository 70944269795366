import React, { FC } from 'react';
import { IconBase, IIcon } from './IconBase/IconBase';

const DeliveryServiceIcon: FC<IIcon> = props => {
  return (
    <IconBase props={props}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 96 96'>
        <path
          d='M92.2,37.8l-9.5-7.6l-6.8,0l-18.9-0.6V65h-2.7V22.7H21.6v4h28.7v24.6l-34.7,0.4l0,4l34.7-0.4V65H34.9
			c-1.5-3.7-5.1-6.3-9.3-6.3s-7.8,2.6-9.3,6.3H4.6v4h11c0.2,5.4,4.6,9.7,10,9.7s9.8-4.3,10-9.7h21.5v0h7.6c0.2,5.3,4.6,9.6,10,9.6
			s9.8-4.3,10-9.6h7.6V37.8z M25.6,74.7c-3.3,0-6-2.7-6-6s2.7-6,6-6s6,2.7,6,6S28.9,74.7,25.6,74.7z M88.2,39.7v4.2H79v-9.7h2.3
			L88.2,39.7z M74.6,74.7c-3.3,0-6-2.7-6-6s2.7-6,6-6s6,2.7,6,6S77.9,74.7,74.6,74.7z M83.9,65c-1.5-3.7-5.1-6.4-9.3-6.4
			s-7.9,2.6-9.3,6.4h-4.2V33.7l14,0.5v13.7h13.1V65H83.9z'
        />
        <path d='M5.9,63.7c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.1s0,0.1,0,0.1S5.9,63.7,5.9,63.7z' />
        <rect x='4.6' y='41.7' width='39' height='4' />
        <rect x='14.6' y='32.7' width='17' height='4' />
      </svg>
    </IconBase>
  );
};

export default DeliveryServiceIcon;
