import React, { FC } from 'react';
import { IconBase, IIcon } from './IconBase/IconBase';

const MailIcon: FC<IIcon> = props => {
  return (
    <IconBase props={props}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 110 96'>
        <path
          d='M97.1,21.2H28c-1.1,0-2,0.9-2,2v14.7h4v-9.8l18.8,16.3L30,66.2v-19h-4v3.5H14v4h12v17.4c0,1.1,0.9,2,2,2h69.1
			c1.1,0,2-0.9,2-2v-49C99.1,22.1,98.2,21.2,97.1,21.2z M95.1,66.8l-19-22.2l17-14.8l-2.6-3L62.6,51L32.8,25.2h62.4V66.8z
			 M61.3,55.2c0.4,0.3,0.8,0.5,1.3,0.5c0.5,0,0.9-0.2,1.3-0.5l9.2-8l19.7,23H31.9L51.8,47L61.3,55.2z'
        />
        <rect x='5.2' y='40.4' width='34.6' height='4' />
        <rect x='5.3' y='61.1' width='16.8' height='4' />
      </svg>
    </IconBase>
  );
};

export default MailIcon;
