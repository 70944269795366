import React, { FC } from 'react';
import { IconBase, IIcon } from './IconBase/IconBase';

const MoreIcon: FC<IIcon> = props => {
  return (
    <IconBase props={props}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 97.39 97.39'>
        <path d='M48.5,85A34.5,34.5,0,1,1,83,50.5,34.54,34.54,0,0,1,48.5,85Zm0-65A30.5,30.5,0,1,0,79,50.5,30.53,30.53,0,0,0,48.5,20Z' />
        <path d='M43,68l-2.83-2.83L54.76,50.5,40.13,35.87,43,33,59,49.1a2,2,0,0,1,0,2.8Z' />
      </svg>
    </IconBase>
  );
};

export default MoreIcon;
