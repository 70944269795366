import React, { FC, ChangeEvent, KeyboardEvent } from 'react';
import { Input } from 'antd';

interface ITextArea {
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onPressEnter?: (e: KeyboardEvent<HTMLTextAreaElement>) => void;
  size?: 'small' | 'middle' | 'large';
  disabled?: boolean;
  maxLength?: number;
  value?: string;
  defaultValue?: string;
  placeholder?: string;
  allowClear?: boolean;
  autoSize?: boolean;
}

export const TextArea: FC<ITextArea> = props => {
  return <Input.TextArea {...props} />;
};

TextArea.defaultProps = {
  size: 'middle',
  disabled: false,
  autoSize: true,
};
