import React from 'react';
import { FileSearchOutlined } from '@ant-design/icons';
import { Icons } from '@digando/react-component-library';

import { IRoutePath } from '../interfaces';

const {
  InterfaceIcon,
  BookNowIcon,
  FleetmanagerIcon,
  LocationIcon,
  BestPriceIcon,
  FleetIcon,
  ConfigIcon,
  ConfigDeviceIcon
} = Icons;

const routes: IRoutePath[] = [
  {
    routeName: 'titles.overview',
    // t('ScreenTitle:titles.overview')
    path: '/',
    icon: <InterfaceIcon />,
  },
  {
    routeName: 'titles.bookings',
    // t('ScreenTitle:titles.bookings')
    path: '/bookings',
    icon: <BookNowIcon />,
  },
  {
    routeName: 'titles.products',
    // t('ScreenTitle.titles.products')
    path: '/products',
    icon: <FleetmanagerIcon />,
  },
  {
    routeName: 'titles.accessories',
    // t('ScreenTitle.titles.accessories')
    path: '/accessories',
    icon: <ConfigDeviceIcon />,
  },
  {
    routeName: 'titles.productTypes',
    // t('ScreenTitle:titles.productTypes')
    path: '/productTypes',
    icon: <FleetIcon />,
  },
  {
    routeName: 'titles.rentalStations',
    // t('ScreenTitle.titles.rentalStations')
    // @todo  placeholder icon used
    path: '/rentalStations',
    icon: <LocationIcon />,
  },
  {
    routeName: 'titles.discounts',
    // t('ScreenTitle.titles.discounts')
    path: '/discounts',
    icon: <BestPriceIcon />,
  },
  {
    routeName: 'titles.tenantSettings',
    // t('ScreenTitle:titles.tenantSettings')
    path: '/tenantSettings',
    icon: <ConfigIcon />,
  },
  {
    routeName: 'titles.importLogs',
    // t('ScreenTitle.titles.import-logs')
    path: '/import-logs',
    icon: <FileSearchOutlined />,
  },
];

export default routes;
