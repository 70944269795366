import React, { FC } from 'react';
import { IconBase, IIcon } from './IconBase/IconBase';

const ConfigDeviceIcon: FC<IIcon> = props => {
  return (
    <IconBase props={props}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 96 96'>
        <path
          d='M86.1,31h-7.3c-0.5,0-0.9,0.2-1.3,0.5L70,37.7c-0.1-1.1-0.2-2.2-0.4-3.2c-0.1-0.5-0.4-1-0.9-1.3
			c-0.5-0.3-1.1-0.4-1.6-0.2c-3.4,1-7.1-0.5-8.9-3.6c-1.8-3.2-1.2-7.1,1.4-9.6c0.4-0.4,0.6-0.9,0.6-1.5c0-0.6-0.2-1.1-0.7-1.5
			c-3.3-2.9-7-5.2-11.2-6.6c-0.5-0.2-1.1-0.1-1.6,0.2c-0.5,0.3-0.8,0.8-1,1.3c-0.8,3.6-4,6.2-7.7,6.2s-6.9-2.6-7.7-6.2
			c-0.1-0.6-0.5-1-1-1.3c-0.5-0.3-1.1-0.3-1.6-0.2c-4.2,1.4-8,3.6-11.2,6.6c-0.4,0.4-0.7,0.9-0.7,1.5s0.2,1.1,0.7,1.5
			c2.7,2.5,3.4,6.6,1.5,9.8c-1.8,3.1-5.5,4.6-9,3.6C8.6,33,8.1,33,7.6,33.3c-0.5,0.3-0.8,0.8-0.9,1.3c-0.4,1.9-0.5,3.9-0.5,5.8
			c0,2.3,0.3,4.7,0.8,7c0.1,0.5,0.5,1,0.9,1.3c0.5,0.3,1,0.4,1.6,0.2c3.5-1,7.1,0.5,8.9,3.6c1.7,3,1.3,6.8-1.1,9.3
			c-0.4,0.4-0.6,0.9-0.5,1.5c0,0.5,0.3,1.1,0.7,1.4c2.2,1.8,5.6,3.5,7.9,4.6l0,0v6.3l37.2,0.1l0-10.4l15-16.9v14.1h-8.8
			c-0.8,0-1.6,0.5-1.9,1.3c-0.3,0.8,0,1.7,0.6,2.2l10.4,8.5c0.4,0.3,0.8,0.4,1.3,0.4c0.5,0,1-0.2,1.4-0.5l7-6.6
			c0.4-0.4,0.6-0.9,0.6-1.5V33C88.1,31.9,87.2,31,86.1,31z M21.5,62.9c2.3-3.7,2.5-8.4,0.3-12.3c-2.3-4-6.8-6.3-11.3-5.9
			c-0.2-1.4-0.3-2.8-0.3-4.2c0-1,0.1-2.1,0.2-3.1c4.5,0.4,9-1.8,11.3-5.9c2.4-4.2,2-9.3-0.8-13.1c2-1.5,4.1-2.8,6.4-3.8
			c1.8,4.3,6.1,7.2,10.9,7.2s9-2.9,10.9-7.2c2.3,1,4.4,2.2,6.4,3.8c-2.7,3.7-3,8.7-0.7,12.9c2.3,4,6.7,6.2,11.2,5.9
			c0.1,1.1,0.2,2.2,0.2,3.3v0.4l-6.4,5.3H44.1l-5.9,5.5h-0.1c-6.1,0-11.1-5-11.1-11.1s5-11.1,11.1-11.1s11.1,5,11.1,11.1v2h4v-2
			c0-8.3-6.8-15.1-15.1-15.1S23,32.2,23,40.6c0,7.9,6.1,14.4,13.9,15.1v3H25.3V65C24,64.4,22.6,63.7,21.5,62.9z M29.3,71.8v-9.1
			h11.6v-8l4.8-4.5h12.8l0,21.6L29.3,71.8z M84.1,65.6L79,70.4l-4.7-3.8h5.1c1.1,0,2-0.9,2-2V43.2c0-0.8-0.5-1.6-1.3-1.9
			c-0.8-0.3-1.7-0.1-2.2,0.5L62.4,59.4l0-10.2L79.6,35h4.5V65.6z'
        />
        <path
          d='M59.6,77.7H28.1c-3.8,0-7,3.1-7,7s3.1,7,7,7h31.5c3.8,0,7-3.1,7-7S63.4,77.7,59.6,77.7z M59.6,87.7H28.1
			c-1.6,0-3-1.3-3-3s1.3-3,3-3h31.5c1.6,0,3,1.3,3,3S61.2,87.7,59.6,87.7z'
        />
      </svg>
    </IconBase>
  );
};

export default ConfigDeviceIcon;
