import React, { FC } from 'react';
import { IconBase, IIcon } from './IconBase/IconBase';

const ConfigIcon: FC<IIcon> = props => {
  return (
    <IconBase props={props}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 96 96'>
        <path
          d='M78.2,10H17.6c-2.6,0-6.4,1.7-6.4,6.4V77h4V26.3h65.3v53.3c0,2-1.5,2.3-2.4,2.4H20v4h58.2
			c2.6,0,6.4-1.7,6.4-6.4V16.4C84.6,13.8,82.9,10,78.2,10z M15.3,22.3v-5.9c0-2,1.5-2.3,2.4-2.4h60.6c2,0,2.3,1.5,2.4,2.4v5.9H15.3z
			'
        />
        <path
          d='M25.8,55.4h23.5c0.8,2.7,3.3,4.6,6.3,4.6s5.4-1.9,6.3-4.6h8.5v-4h-8.5c-0.8-2.7-3.3-4.6-6.3-4.6
			s-5.4,1.9-6.3,4.6H25.8V55.4z M55.6,50.8c1.4,0,2.6,1.2,2.6,2.6c0,1.4-1.2,2.6-2.6,2.6S53,54.8,53,53.4C53,52,54.2,50.8,55.6,50.8
			z'
        />
        <path
          d='M40.8,43.4c2.9,0,5.4-1.9,6.3-4.6h23.3v-4H47c-0.8-2.7-3.3-4.6-6.3-4.6c-2.9,0-5.4,1.9-6.3,4.6H26v4h8.5
			C35.3,41.5,37.8,43.4,40.8,43.4z M40.8,34.3c1.4,0,2.6,1.2,2.6,2.6c0,1.4-1.2,2.6-2.6,2.6c-1.4,0-2.6-1.2-2.6-2.6
			C38.2,35.4,39.3,34.3,40.8,34.3z'
        />
        <path
          d='M40.8,63.3c-2.9,0-5.4,1.9-6.3,4.6H26v4h8.5c0.8,2.7,3.3,4.6,6.3,4.6c2.9,0,5.4-1.9,6.3-4.6h23.3v-4H47
			C46.2,65.2,43.7,63.3,40.8,63.3z M40.8,72.4c-1.4,0-2.6-1.2-2.6-2.6c0-1.4,1.2-2.6,2.6-2.6c1.4,0,2.6,1.2,2.6,2.6
			C43.3,71.3,42.2,72.4,40.8,72.4z'
        />
        <path d='M15.4,80c-1,0-1.7,0.8-1.7,1.8c0,1,0.8,1.8,1.7,1.8s1.7-0.8,1.7-1.8C17.1,80.8,16.3,80,15.4,80z' />
        <path d='M59.7,16.5c-1,0-1.7,0.8-1.7,1.8c0,1,0.8,1.8,1.7,1.8s1.7-0.8,1.7-1.8C61.4,17.3,60.6,16.5,59.7,16.5z' />
        <path d='M67,16.5c-1,0-1.7,0.8-1.7,1.8c0,1,0.8,1.8,1.7,1.8s1.7-0.8,1.7-1.8C68.7,17.3,67.9,16.5,67,16.5z' />
        <path d='M74.2,16.5c-1,0-1.7,0.8-1.7,1.8c0,1,0.8,1.8,1.7,1.8s1.7-0.8,1.7-1.8C76,17.3,75.2,16.5,74.2,16.5z' />
      </svg>
    </IconBase>
  );
};

export default ConfigIcon;
