import React, { FC } from 'react';
import { IconBase, IIcon } from './IconBase/IconBase';

const TwentyFourIcon: FC<IIcon> = props => {
  return (
    <IconBase props={props}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 96 96'>
        <path
          d='M80.7,45.1v2c0,17.9-14.6,32.5-32.5,32.5S15.6,65.1,15.6,47.1s14.6-32.5,32.5-32.5c5.5,0,11,1.4,15.8,4.1
			l0.4,0.2l-10.9,2.7l1,3.9L69,21.9c0.5-0.1,1-0.5,1.2-0.9c0.3-0.5,0.4-1,0.2-1.5L66.8,4.9l-3.9,1l2.2,9c-5.2-2.8-11.1-4.2-17-4.2
			C28,10.6,11.6,27,11.6,47.1c0,20.2,16.4,36.5,36.5,36.5s36.5-16.4,36.5-36.5v-2H80.7z'
        />
        <path
          d='M24.1,56.2c0,1.3,1,2.3,2.2,2.3h10.8c1,0,1.7-0.8,1.7-1.7c0-1-0.8-1.7-1.7-1.7H30c6-6.6,8.6-9.9,8.6-13.6
			c0-4.2-3.2-6.8-7.3-6.8c-2.6,0-4.7,0.9-6.5,2.7c-0.3,0.3-0.5,0.7-0.5,1.2c0,0.9,0.7,1.7,1.7,1.7c0.5,0,0.9-0.2,1.2-0.5
			c1.1-1,2.4-1.6,4-1.6c1.8,0,3.4,1.3,3.4,3.4c0,2.9-2,5.1-9.9,13.2C24.4,55.1,24.1,55.6,24.1,56.2z'
        />
        <path
          d='M51.4,58.7c1.1,0,1.9-0.9,1.9-2V53h1c0.9,0,1.7-0.8,1.7-1.7c0-0.9-0.8-1.7-1.7-1.7h-1V37.9
			c0-1.7-1.4-3.1-3.1-3.1c-1.1,0-2.1,0.7-2.7,1.6l-7.4,13.2c-0.2,0.3-0.3,0.7-0.3,1.1c0,1.3,1.1,2.3,2.3,2.3h7.3v3.7
			C49.5,57.8,50.3,58.7,51.4,58.7z M43.5,49.6L49.5,39v10.6H43.5z'
        />
        <path
          d='M62.2,43.7v-7.2c0-1-0.8-1.8-1.8-1.8c-1,0-1.8,0.8-1.8,1.8v20.4c0,1,0.8,1.8,1.8,1.8c1,0,1.8-0.8,1.8-1.8
			V46.5c0.7-1.2,2-2.4,3.5-2.4c1.5,0,2.5,0.7,2.5,2.7v10.1c0,1,0.8,1.8,1.7,1.8c1,0,1.8-0.8,1.8-1.8V45.6c0-3-1.4-4.6-4.4-4.6
			C65,41,63.1,42.4,62.2,43.7z'
        />
      </svg>
    </IconBase>
  );
};

export default TwentyFourIcon;
