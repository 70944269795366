import React, { FC } from 'react';
import { IconBase, IIcon } from './IconBase/IconBase';

const SearchIcon: FC<IIcon> = props => {
  return (
    <IconBase props={props}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 96 96'>
        <path
          d='M74.3,26.4c-4.5-5.6-10.8-9.2-18-10c-7.1-0.8-14.1,1.2-19.7,5.7c-5.6,4.5-9.1,10.9-9.9,18.1
			c-0.4,3.4-0.1,6.8,0.8,10.1l0.5,1.9l3.9-1l-0.5-1.9c-0.8-2.8-1-5.8-0.7-8.6c0.7-6.1,3.7-11.6,8.5-15.4c4.8-3.8,10.7-5.6,16.8-4.9
			c6.1,0.7,11.5,3.7,15.3,8.5c3.8,4.8,5.5,10.8,4.8,16.9c-0.7,6.1-3.7,11.6-8.5,15.4c-4.8,3.8-10.7,5.6-16.8,4.9
			c-3.4-0.4-6.6-1.5-9.5-3.4c-0.8-0.5-1.7-0.4-2.4,0.2l-1.4,1.2c0,0-0.1,0.1-0.1,0.1L19.7,82.1c-0.1,0-0.1-0.1-0.2-0.1l-3.5-3.6
			c-0.1-0.1-0.1-0.1-0.2-0.2l19.1-19.3L32,56.1L12.5,75.8c-0.9,0.9-1.5,3.2,0.6,5.3l3.5,3.6c0.4,0.4,1.6,1.5,3.2,1.5c0,0,0,0,0,0
			c0.8,0,1.6-0.3,2.1-0.9l18.1-18.3l0.2-0.2c3.1,1.7,6.4,2.8,9.9,3.2c7.1,0.8,14.1-1.2,19.7-5.7c5.6-4.5,9.1-10.9,9.9-18.1
			C80.7,39.1,78.7,32,74.3,26.4z'
        />
        <path
          d='M44.8,32.9c2.8-2.3,6.4-3.3,10-2.9c5.7,0.6,10.4,4.9,11.7,10.6l0.4,2l3.9-0.9l-0.4-2
			c-1.6-7.4-7.7-12.9-15.1-13.8c-4.7-0.5-9.3,0.8-13,3.8c-3.7,3-6,7.2-6.5,11.9c-1,8.6,4.3,16.5,12.5,19l1.9,0.6l1.1-3.8l-1.9-0.6
			c-6.4-1.9-10.4-8-9.7-14.7C40.1,38.4,41.9,35.2,44.8,32.9z'
        />
      </svg>
    </IconBase>
  );
};

export default SearchIcon;
