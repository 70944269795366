import React, { FC } from 'react';
import { IconBase, IIcon } from './IconBase/IconBase';

const BestPriceIcon: FC<IIcon> = props => {
  return (
    <IconBase props={props}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 96 96'>
        <path
          d='M35.1,43.5l3.7,0c1.9,4.8,6.6,8.2,12.1,8.2h2v-4h-2c-3.2,0-6-1.7-7.6-4.2l6.9,0l0-4l-8.3,0c0-0.3,0-0.5,0-0.8
			c0-0.3,0-0.6,0-0.9l8.3,0l0-4l-6.9,0c1.6-2.4,4.4-4.1,7.5-4.1h2v-4h-2c-5.4,0-10.1,3.3-12,8.1l-3.7,0l0,4l2.7,0c0,0.3,0,0.6,0,0.9
			c0,0.3,0,0.6,0,0.8l-2.8,0L35.1,43.5z'
        />
        <path
          d='M80.7,69L68.9,52.7c2.8-4.1,4.5-9.1,4.5-14.5c0-14.1-11.5-25.6-25.6-25.6S22.2,24.1,22.2,38.2
			c0,5,1.5,9.7,4,13.7L14.9,69.1c-0.5,0.7-0.4,1.6,0,2.3c0.5,0.7,1.3,1,2.1,0.8l13.8-3.4l3.1,14.4c0.2,0.8,0.8,1.4,1.6,1.5
			c0.1,0,0.2,0,0.4,0c0.7,0,1.3-0.3,1.7-0.9l10.2-15.9l10.2,15.9c0.4,0.6,1,0.9,1.7,0.9c0.1,0,0.2,0,0.4,0c0.8-0.1,1.4-0.8,1.6-1.5
			l3.1-14.4l13.8,3.4c0.8,0.2,1.7-0.1,2.1-0.8S81.2,69.7,80.7,69z M47.8,16.7c11.9,0,21.6,9.7,21.6,21.6s-9.7,21.6-21.6,21.6
			s-21.6-9.7-21.6-21.6S35.9,16.7,47.8,16.7z M36.8,77.5l-2.5-11.7c-0.1-0.5-0.4-1-0.9-1.3c-0.5-0.3-1-0.4-1.5-0.2L21.1,67l7.7-11.7
			c4.2,4.6,10,7.7,16.5,8.4c0,0.2,0.1,0.3,0.2,0.5L36.8,77.5z M63.8,64.3c-0.5-0.1-1.1,0-1.5,0.2c-0.5,0.3-0.8,0.7-0.9,1.3
			l-2.5,11.7l-8.6-13.4c0.1-0.2,0.1-0.3,0.2-0.5c6.2-0.6,11.8-3.5,15.9-7.7l8,11L63.8,64.3z'
        />
      </svg>
    </IconBase>
  );
};

export default BestPriceIcon;
