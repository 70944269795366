import React, { FC } from 'react';
import { IconBase, IIcon } from './IconBase/IconBase';

const LocationIcon: FC<IIcon> = props => {
  return (
    <IconBase props={props}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 96 96'>
        <path
          d='M74.7,54.5L73,53.3l-2.2,3.3l1.7,1.1c2.6,1.8,5.7,4.6,5.7,8.4c0,7.9-13.7,14.6-30,14.6s-30-6.7-30-14.6
			c0-6.3,8.6-11.7,21.3-13.5l0.4-0.1c2.4,3.1,4.8,5.8,6.5,7.6c0.7,0.7,1.6,1.1,2.6,1.1c0.7,0,1.4-0.2,2-0.6
			c8.9,0.3,14.3,3.3,14.3,5.5c0,2.2-6.4,5.5-16.5,5.5s-16.5-3.3-16.5-5.5c0-1.1,2-2.9,6.4-4.2l1.9-0.6l-1.1-3.8l-1.9,0.6
			c-5.9,1.7-9.2,4.6-9.2,8c0,4.7,7,9.5,20.5,9.5s20.5-4.8,20.5-9.5c0-4.6-6-8.2-14.8-9.2c5.7-6.6,14.1-17.5,14.1-24.8
			c0-10.8-8.8-19.5-19.5-19.5s-19.5,8.8-19.5,19.5c0,4.8,3.6,11.2,7.7,16.8c-14,2.4-22.9,9-22.9,17.2c0,10.4,14.9,18.6,34,18.6
			s34-8.2,34-18.6C82.1,61.8,79.5,57.8,74.7,54.5z M48.9,16.6c8.6,0,15.5,7,15.5,15.5c0,5.7-7.7,16.3-15.2,24.5c0,0-0.1,0-0.1,0
			h-0.6C41,48.4,33.3,37.9,33.3,32.2C33.3,23.6,40.3,16.6,48.9,16.6z'
        />
        <path
          d='M48.9,40.7c5.8,0,10.5-4.7,10.5-10.5s-4.7-10.5-10.5-10.5s-10.5,4.7-10.5,10.5S43.1,40.7,48.9,40.7z
            M48.9,23.7c3.6,0,6.5,2.9,6.5,6.5s-2.9,6.5-6.5,6.5s-6.5-2.9-6.5-6.5S45.3,23.7,48.9,23.7z'
        />
      </svg>
    </IconBase>
  );
};

export default LocationIcon;
