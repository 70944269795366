import React, { FC, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import styled from '@emotion/styled';
import { Spin } from 'antd';

interface ILoaderLayout {
  children: React.ReactNode;
}

export const LoaderLayout: FC<ILoaderLayout> = ({children}) => {
  const router = useRouter();
  const [isLoaderLayoutActive, setIsLoaderLayoutActive] = useState(false);

  useEffect(() => {
    const handleStart = (urlString: string): void => {
      // if url is the same skip
      if (urlString === router.asPath) {
        return;
      }

      const currentPaths = router.asPath.split(/[/?]/);
      currentPaths.shift();

      const paths = urlString.split(/[/?]/);
      paths.shift();

      if(currentPaths[0] !== paths[0]){
        setIsLoaderLayoutActive(true);
      }
    };

    const handleComplete = (): void => {
      setIsLoaderLayoutActive(false);
    };

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);

    return (): void => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleComplete);
    };
  });

  return (
    <>
      <StyledLayout className={isLoaderLayoutActive ? 'active' : ''}>
        <FadeLayer className='fade'/>
        <StyledLoader className='loader'>
          <Spin size={'large'} />
        </StyledLoader>
      </StyledLayout>
      {children}
    </>
  );
};

const StyledLayout = styled.div`
  position: relative;
  pointer-events: none;

  &.active {
    pointer-events: auto;
  }

  &.active .fade, &.active .loader {
    opacity: 1;
  }
`;

const FadeLayer = styled.div`
  z-index: 100;
  width: 100vw;
  height: 100%;
  background-color: #fff;
  position: fixed;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
`;

const StyledLoader = styled.div`
  z-index: 101;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
`;
