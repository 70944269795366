import React, { FC } from 'react';
import { IIcon, IconBase } from './IconBase/IconBase';

const CheckmarkIcon: FC<IIcon> = props => {
  return (
    <IconBase props={props}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 96 96'>
        <path d='M42.68,52.89,32.45,43.32,27,49.42,40,61.5a4.11,4.11,0,0,0,5.55-.06L69,39.42l-5.61-6Z' />
      </svg>
    </IconBase>
  );
};

export default CheckmarkIcon;
