import React, { FC } from 'react';
import { IIcon } from '../Icons/IconBase/IconBase';
import {
  SearchIcon,
  MailIcon,
  ConfigIcon,
  ConfigDeviceIcon,
  FleetIcon,
  FleetmanagerIcon,
  FlexibleIcon,
  InterfaceIcon,
  LiveIcon,
  LocationIcon,
  PartnerIcon,
  PlannerIcon,
  TwentyFourSevenIcon,
  BookNowIcon,
  BestPriceIcon,
  CheckmarkIcon,
  DeliveryServiceIcon,
  MoreIcon,
  UserIcon,
  SecondUserIcon,
} from '../Icons';

interface IIconSelect extends IIcon {
  icon:
    | 'search'
    | 'mail'
    | 'config'
    | 'config-device'
    | 'delivery'
    | 'fleet'
    | 'fleetmanager'
    | 'flexible'
    | 'interface'
    | 'live'
    | 'location'
    | 'partner'
    | 'planer'
    | 'twentyfourseven'
    | 'bestprice'
    | 'booknow'
    | 'checkmark'
    | 'more'
    | 'user'
    | 'user-alt'
    | string;
}

export const IconSelect: FC<IIconSelect> = ({ icon, ...props }) => {
  switch (icon) {
  case 'search':
    return (
      <SearchIcon size={props.size ? props.size : '1em'} fill={props.fill ? props.fill : 'currentColor'} {...props} />
    );
  case 'mail':
    return (
      <MailIcon size={props.size ? props.size : '1em'} fill={props.fill ? props.fill : 'currentColor'} {...props} />
    );
  case 'config':
    return (
      <ConfigIcon size={props.size ? props.size : '1em'} fill={props.fill ? props.fill : 'currentColor'} {...props} />
    );
  case 'config-device':
    return (
      <ConfigDeviceIcon
        size={props.size ? props.size : '1em'}
        fill={props.fill ? props.fill : 'currentColor'}
        {...props}
      />
    );
  case 'delivery':
    return (
      <DeliveryServiceIcon
        size={props.size ? props.size : '1em'}
        fill={props.fill ? props.fill : 'currentColor'}
        {...props}
      />
    );
  case 'fleet':
    return (
      <FleetIcon size={props.size ? props.size : '1em'} fill={props.fill ? props.fill : 'currentColor'} {...props} />
    );
  case 'fleetmanager':
    return (
      <FleetmanagerIcon
        size={props.size ? props.size : '1em'}
        fill={props.fill ? props.fill : 'currentColor'}
        {...props}
      />
    );
  case 'flexible':
    return (
      <FlexibleIcon
        size={props.size ? props.size : '1em'}
        fill={props.fill ? props.fill : 'currentColor'}
        {...props}
      />
    );
  case 'interface':
    return (
      <InterfaceIcon
        size={props.size ? props.size : '1em'}
        fill={props.fill ? props.fill : 'currentColor'}
        {...props}
      />
    );
  case 'live':
    return (
      <LiveIcon size={props.size ? props.size : '1em'} fill={props.fill ? props.fill : 'currentColor'} {...props} />
    );
  case 'location':
    return (
      <LocationIcon
        size={props.size ? props.size : '1em'}
        fill={props.fill ? props.fill : 'currentColor'}
        {...props}
      />
    );
  case 'partner':
    return (
      <PartnerIcon
        size={props.size ? props.size : '1em'}
        fill={props.fill ? props.fill : 'currentColor'}
        {...props}
      />
    );
  case 'planer':
    return (
      <PlannerIcon
        size={props.size ? props.size : '1em'}
        fill={props.fill ? props.fill : 'currentColor'}
        {...props}
      />
    );
  case 'twentyfourseven':
    return (
      <TwentyFourSevenIcon
        size={props.size ? props.size : '1em'}
        fill={props.fill ? props.fill : 'currentColor'}
        {...props}
      />
    );
  case 'bestprice':
    return (
      <BestPriceIcon
        size={props.size ? props.size : '1em'}
        fill={props.fill ? props.fill : 'currentColor'}
        {...props}
      />
    );
  case 'booknow':
    return (
      <BookNowIcon
        size={props.size ? props.size : '1em'}
        fill={props.fill ? props.fill : 'currentColor'}
        {...props}
      />
    );
  case 'checkmark':
    return (
      <CheckmarkIcon
        size={props.size ? props.size : '1em'}
        fill={props.fill ? props.fill : 'currentColor'}
        {...props}
      />
    );
  case 'more':
    return (
      <MoreIcon size={props.size ? props.size : '1em'} fill={props.fill ? props.fill : 'currentColor'} {...props} />
    );
  case 'user':
    return (
      <UserIcon size={props.size ? props.size : '1em'} fill={props.fill ? props.fill : 'currentColor'} {...props} />
    );
  case 'user-alt':
    return (
      <SecondUserIcon
        size={props.size ? props.size : '1em'}
        fill={props.fill ? props.fill : 'currentColor'}
        {...props}
      />
    );
  default:
    return <div>NO ICON PROVIDED</div>;
  }
};
