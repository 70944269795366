// @todo: Convert themed-variables to TS
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { themeVariables } from '../themed-variables';

export const modifySizeMultiply = (multiplier: number): string => {
  return `calc(${multiplier} * var(--font-size-steps))`; // @todo: use css variable.
};

export const modifySizeAddition = (fontString: string, modifyFrac: number): string => {
  const fontNumber = parseFloat(fontString);
  const fontUnit = fontString.replace(/[0-9]/g, '');

  return `${fontNumber + modifyFrac}${fontUnit}`;
};

/**
 * BORDER RADIUS
 * Button-big: 64px
 * Button-middle: 16px
 * Button-small: 11px
 *
 * checkbox: 2px
 * input: 3px
 *
 * DRAWER
 * big: 28px
 * regular: 16px
 * small: 8px
 *
 *
 * BORDER RADIUS
 * oversize: 64px
 * big: 28px
 * regular/middle: 16px
 * small: 8px
 * min: 3px
 *
 * DRAWER
 */
// Old UI variables
const inputPaddingVertical = '0.5em'; // @todo: check for new ui
const inputPaddingHorizontal = '12px'; // @todo: check for new ui
const fontSizeBase = '16px';  // @todo: check for new ui
const fontSizeSm = '14px'; // @todo: check for new ui
const fontSizeLg = `${modifySizeAddition(fontSizeBase, 2)}`; // @todo: check for new ui
const paddingMd = '16px'; // @todo: check for new ui
const paddingSm = '12px'; // @todo: check for new ui

export const theme = {
  // New UI variables
  ...themeVariables,

  // Old UI Variables
  'input-padding-vertical-base': inputPaddingVertical,
  'input-padding-horizontal': inputPaddingHorizontal,
  'font-size-sm': fontSizeSm,
  'font-size-lg': fontSizeLg,

  'padding-md': paddingMd,
  'padding-sm': paddingSm,
};
