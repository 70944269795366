import React, { FC } from 'react';
import { Input } from 'antd';
import { PasswordProps } from 'antd/es/input';

export const PasswordInput: FC<PasswordProps> = props => {
  return <Input.Password {...props} />;
};

PasswordInput.defaultProps = {
  type: 'password',
  size: 'middle',
  disabled: false,
  visibilityToggle: true,
};
