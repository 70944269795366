import React, { FC } from 'react';
import { IconBase, IIcon } from './IconBase/IconBase';

const InterfaceIcon: FC<IIcon> = props => {
  return (
    <IconBase props={props}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 96 96'>
        <path
          d='M55.5,39.9c3.9,0,7-3.1,7-7v-6c0-3.9-3.1-7-7-7h-3H41.8h-4v4h4v4h-8v4h8v4h-4v4h4h10.7H55.5z M45.8,31.9v-4
			v-4h6.7h3c1.7,0,3,1.3,3,3v6c0,1.7-1.3,3-3,3h-3h-6.7V31.9z'
        />
        <path
          d='M80.7,72.7h-2.2v-13H50.2v-6c0-0.1,0-0.2,0-0.3c12-1,21.5-11.1,21.5-23.4c0-13-10.5-23.5-23.5-23.5
			S24.7,17,24.7,29.9c0,12.3,9.5,22.4,21.5,23.4c0,0.1,0,0.2,0,0.3v6H17.4v13h-1.8c-2.8,0-5,2.2-5,5v2c0,2.8,2.2,5,5,5h8
			c2.8,0,5-2.2,5-5v-2c0-2.8-2.2-5-5-5h-2.2v-9h24.8v9h-2c-2.8,0-5,2.2-5,5v2c0,2.8,2.2,5,5,5h8c2.8,0,5-2.2,5-5v-2c0-2.8-2.2-5-5-5
			h-2v-9h24.2v9h-1.8c-2.8,0-5,2.2-5,5v2c0,2.8,2.2,5,5,5h8c2.8,0,5-2.2,5-5v-2C85.7,74.9,83.4,72.7,80.7,72.7z M28.7,29.9
			c0-10.8,8.7-19.5,19.5-19.5s19.5,8.7,19.5,19.5s-8.7,19.5-19.5,19.5S28.7,40.7,28.7,29.9z M24.7,77.7v2c0,0.5-0.5,1-1,1h-8
			c-0.5,0-1-0.5-1-1v-2c0-0.5,0.5-1,1-1h8C24.2,76.7,24.7,77.1,24.7,77.7z M53.2,77.7v2c0,0.5-0.5,1-1,1h-8c-0.5,0-1-0.5-1-1v-2
			c0-0.5,0.5-1,1-1h8C52.7,76.7,53.2,77.1,53.2,77.7z M81.7,79.7c0,0.5-0.5,1-1,1h-8c-0.5,0-1-0.5-1-1v-2c0-0.5,0.5-1,1-1h8
			c0.5,0,1,0.5,1,1V79.7z'
        />
      </svg>
    </IconBase>
  );
};

export default InterfaceIcon;
