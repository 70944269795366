import React from 'react';
import { NextComponentType } from 'next';
import { AppInitialProps } from 'next/app';
import { SessionProvider } from 'next-auth/react';
import { useRouter } from 'next/router';
import { appWithTranslation } from 'next-i18next';
import { ApolloProvider } from '@apollo/client';
import { BasicLayout } from '@layout/basic';
import { getApolloClient } from '../plugins/apollo';
import '../style/index.css';
import {
  theme,
  Fonts,
  FontBarlowWeights,
} from '@digando/react-component-library';
import { ConfigProvider } from 'antd';
import { LoaderLayout } from '@layout/loader-layout';

export interface IAppProps extends AppInitialProps {
  Component: NextComponentType;
  router: any;
}

const App: React.FunctionComponent<IAppProps> = ({
  Component,
  pageProps: { session, ...pageProps },
}) => {
  const router = useRouter();
  const apolloClient = getApolloClient({ session, router });
  const pagesWithoutLayout: string[] = ['/login'];

  return (
    <main>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: theme.primaryColor,
            colorPrimaryActive: theme.primaryHoverColor,
            colorPrimaryTextHover: theme.primaryHoverColor,
            colorPrimaryBgHover: theme.primaryHoverColor,
            colorPrimaryBg: theme.primaryHoverColor,
            colorPrimaryTextActive: theme.primaryHoverColor,
            colorText: theme.black,
            colorTextDisabled: theme.black,
            colorLink: theme.primaryColor,
            colorLinkHover: theme.primaryColor,
            colorLinkActive: theme.primaryColor,
            fontFamily: Fonts.BARLOW,
            fontWeightStrong: FontBarlowWeights.SEMI_BOLD,
            fontSize: 17,
            controlItemBgActive: theme.primaryActiveBgColor,
          },
        }}
      >
        {pageProps.statusCode ? (
          <p>error</p>
        ) : (
          <SessionProvider session={session}>
            <ApolloProvider client={apolloClient}>
              {pagesWithoutLayout.includes(router.route)
                ? <Component {...pageProps} />
                : <BasicLayout>
                  <LoaderLayout>
                    <Component {...pageProps} />
                  </LoaderLayout>
                </BasicLayout>
              }
            </ApolloProvider>
          </SessionProvider>
        )}
      </ConfigProvider>
    </main>
  );
};

export default appWithTranslation(App);
