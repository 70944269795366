import React, { FC, ReactNode } from 'react';
import { List as AntdList } from 'antd';

const { Meta } = AntdList.Item;

interface IListItem {
  actions?: ReactNode[];
  extra?: string | React.ReactNode;
  children?: React.ReactNode;
}

export const ListItem: FC<IListItem> = props => (
  <AntdList.Item {...props}>
    {props.children}
  </AntdList.Item>
);

interface IListItemMeta {
  avatar?: React.ReactNode;
  description?: string | React.ReactNode;
  title: string | React.ReactNode;
  children?: React.ReactNode;
}

export const ListItemMeta: FC<IListItemMeta> = props => (
  <Meta {...props}>
    {props.children}
  </Meta>
);
