import React, { FC } from 'react';
import { Input as AntdInput, InputProps } from 'antd';
import styled from '@emotion/styled';

interface ILabeledInput extends InputProps {
  label: string;
  name: string;
}

export const LabeledInput: FC<ILabeledInput> = props => {
  const { name, label } = props;

  return (
    <LabeledInputWrapper className={props.className}>
      <StyledLabel htmlFor={name}>{label}</StyledLabel>
      <AntdInput {...props} />
    </LabeledInputWrapper>
  );
};

LabeledInput.defaultProps = {
  type: 'text',
  size: 'middle',
  disabled: false,
  allowClear: false,
};

const LabeledInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
`;

const StyledLabel = styled.label`
  font-size: 0.8em;
  font-weight: bold;
  padding: 0;
  margin: 0;
`;
