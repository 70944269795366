import React, { FC } from 'react';
import { IconBase, IIcon } from './IconBase/IconBase';

const PlannerIcon: FC<IIcon> = props => {
  return (
    <IconBase props={props}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 96 96'>
        <path
          d='M86.3,32h-7.8c-0.5,0-0.9,0.2-1.3,0.5L60.6,46.2H44.1l-7.2,6.7v5.7H25.3v17.1l37.2,0.1l0-9.7
			c0.1-0.1,0.1-0.1,0.2-0.2L77.1,49v12.7H69c-0.8,0-1.6,0.5-1.9,1.3c-0.3,0.8,0,1.7,0.6,2.2l9.8,8c0.4,0.3,0.8,0.4,1.3,0.4
			c0.5,0,0.9-0.2,1.3-0.5l7.5-6.2c0.5-0.4,0.7-0.9,0.7-1.5V34C88.3,32.9,87.4,32,86.3,32z M29.3,71.8v-9.1h11.6v-8l4.8-4.5h12.8
			l0,21.6L29.3,71.8z M84.3,64.5l-5.6,4.6l-4.2-3.4h4.5c1.1,0,2-0.9,2-2V43.6c0-0.8-0.5-1.6-1.3-1.9c-0.8-0.3-1.7-0.1-2.2,0.6
			L62.4,60l0-10.2c0,0,0,0,0,0L79.2,36h5.1V64.5z'
        />
        <path
          d='M79.1,77.6c0,1.1-0.9,2-2,2H64.3c-1.2-1.2-2.9-1.9-4.7-1.9H28.1c-1.8,0-3.5,0.7-4.8,1.9c-0.1,0-0.1,0-0.2,0
			h-6c-1.1,0-2-0.9-2-2v-47h64v0.1h4V17.6c0-3.3-2.7-6-6-6H66.8V4.8h-4v6.8H30.7V4.8h-4v6.8h-9.6c-3.3,0-6,2.7-6,6v2h4v-2
			c0-1.1,0.9-2,2-2h9.6v7.2h4v-7.2h32.1v7.2h4v-7.2h10.4c1.1,0,2,0.9,2,2v9h-68v51c0,3.3,2.7,6,6,6h4.1c-0.1,0.4-0.1,0.7-0.1,1.1
			c0,3.8,3.1,7,7,7h31.5c3.8,0,7-3.1,7-7c0-0.4,0-0.7-0.1-1.1h10.6c3.3,0,6-2.7,6-6v-2h-4V77.6z M59.6,87.7H28.1c-1.6,0-3-1.3-3-3
			s1.3-3,3-3h31.5c1.6,0,3,1.3,3,3S61.2,87.7,59.6,87.7z'
        />
      </svg>
    </IconBase>
  );
};

export default PlannerIcon;
