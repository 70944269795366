import React, { FC } from 'react';
import { Typography } from 'antd';
import { ParagraphProps } from 'antd/es/typography/Paragraph';

export const Paragraph: FC<ParagraphProps> = props => (
  <Typography.Paragraph {...props}>{props.children}</Typography.Paragraph>
);

Paragraph.defaultProps = {
  code: false,
  ellipsis: false,
  copyable: false,
  delete: false,
};
