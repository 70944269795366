import React, { FC } from 'react';
import { IconBase, IIcon } from './IconBase/IconBase';

const PartnerIcon: FC<IIcon> = props => {
  return (
    <IconBase props={props}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 96 96'>
        <path
          d='M75.2,18.2l-10,6.5H53.7c-0.2,0-0.4,0.1-0.5,0.1l-8.9,2.6l-9.2-2.5l-9.9-6.7L7.7,39.5l8.9,8.3l7,9.5
			c-2.1,2.4-2,5.9,0.3,8.1c0.9,0.9,2.1,1.5,3.3,1.7c0,0.2,0,0.4,0,0.7c0,1.6,0.6,3.1,1.7,4.2c1.1,1.1,2.5,1.7,4,1.7
			c0,1.5,0.6,3,1.7,4.1c1.1,1.1,2.6,1.7,4.2,1.7c0.2,0,0.5,0,0.7,0c0.2,1.3,0.8,2.4,1.7,3.4c1.1,1.1,2.6,1.7,4.2,1.7
			s3.1-0.6,4.2-1.7l0.7-0.7l0.2,0.1c1.1,1.1,2.6,1.7,4.1,1.7c1.6,0,3.1-0.6,4.2-1.7c1.1-1.1,1.7-2.5,1.7-4c1.5,0,3-0.7,4-1.7
			c1.1-1.1,1.7-2.5,1.7-4c1.5,0,3-0.6,4-1.7c1.1-1.1,1.7-2.5,1.7-4c1.5,0,3-0.7,4-1.7c1.9-1.9,2.3-5,0.9-7.3l5.1-10.7l8.1-8.5
			L75.2,18.2z M86.2,38.3l-5.3,4.6L68.5,27.1l6.1-3.9L86.2,38.3z M31.5,27L17.7,43.8l-5.3-4.6l13.8-16.1L31.5,27z M57.3,51.5
			c-0.7,0.7-0.7,1.9,0,2.6l10.5,10.6c0.4,0.4,0.6,1,0.6,1.6s-0.2,1.1-0.6,1.6c-0.8,0.8-2.3,0.8-3.1,0l-7-7.1c-0.7-0.7-1.9-0.7-2.6,0
			c-0.7,0.7-0.7,1.9,0,2.6l6.9,6.9c0,0,0.1,0.1,0.1,0.1l0.7,0.8c0.1,0.1,0.1,0.2,0.1,0.2c0.1,1.7-1,2.8-2.3,2.8c0,0,0,0,0,0
			c-0.6,0-1.1-0.2-1.5-0.6L55,69.8c-0.7-0.7-1.9-0.7-2.6,0c-0.7,0.7-0.7,1.9,0,2.6l3.7,3.7c0,0,0.1,0.1,0.1,0.1l0.5,0.6
			c0.1,0.1,0.1,0.2,0.1,0.3c0.5,2.9-2.4,3.7-3.7,2.3c-0.1-0.1-0.2-0.2-0.3-0.2l-0.1-0.1c0-0.1,0.1-0.1,0.1-0.2c0,0,0.1-0.1,0.1-0.2
			c0.1-0.2,0.1-0.3,0.2-0.5c0.3-0.9,0.3-1.7,0.2-2.6c-0.2-1.7-1.1-3.2-2.5-4.1c-0.7-0.5-1.4-0.8-2.2-0.9c0.3-1.9-0.2-3.8-1.5-5.1
			c-1.1-1.1-2.6-1.8-4.2-1.9c0-1.5-0.6-2.9-1.6-3.9c-1.1-1.2-2.6-1.8-4.2-1.9c-0.4,0-0.8,0-1.2,0.1c-0.2-1.3-0.9-2.5-1.9-3.3
			c-1.1-0.9-2.4-1.4-3.8-1.4c0,0,0,0-0.1,0c-1.4,0-2.7,0.5-3.8,1.3l-5.9-8L35,28.7l4.3,1.2l-6,10.7c-1.2,2.1-1,4.8,0.7,6.9
			c0.4,0.5,0.9,0.8,1.4,1c0.1,0.1,0.3,0.2,0.4,0.2l0.1,0c0.2,0.1,0.5,0.2,0.7,0.3c1.2,0.4,2.4,0.4,3.5,0.1c1.5-0.4,2.8-1.4,3.6-2.8
			l4-7l6.9,0.5L73.5,59c0.9,0.9,0.8,2.3,0,3.1c-0.9,0.9-2.3,0.9-3.1,0c0,0-0.1-0.1-0.1-0.1L59.9,51.5C59.2,50.8,58,50.8,57.3,51.5z
			 M43.1,77.8c0-0.1,0-0.2,0.1-0.2l2.6-2.6c0.1-0.1,0.1-0.1,0.2-0.1c0.3-0.2,0.6-0.4,1-0.5l0.1,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0h0.3
			c0.6,0,1.2,0.3,1.6,0.7c0.1,0.1,0.1,0.1,0.1,0.2c0.7,0.9,0.6,2.2-0.3,3L47,80.1C45.6,81.4,42.9,80.6,43.1,77.8z M26.5,59.5l2-2
			c0.1-0.1,0.1-0.1,0.2-0.1c0.4-0.3,0.8-0.4,1.2-0.5c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.6,0,1.2,0.3,1.6,0.7
			c0.8,0.9,0.8,2.3-0.1,3.2L29,63.3c-0.1,0.1-0.2,0.1-0.2,0.1c-1.8,0.1-2.9-1-2.9-2.3C25.9,60.5,26.1,60,26.5,59.5z M33.8,69.9
			c-1.8,0.2-3-1-3-2.3c0-0.6,0.2-1.2,0.6-1.6l3.8-3.8c0.4-0.4,1-0.6,1.6-0.6c0.3,0,0.6,0.1,0.9,0.2c0.8,0.4,1.3,1.3,1.2,2.3
			c-0.1,0.5-0.3,1-0.7,1.4l-4.3,4.3C34,69.9,33.9,69.9,33.8,69.9z M37.3,71.9l3.8-3.8c0.4-0.4,1-0.6,1.6-0.6c0.4,0,0.8,0.1,1.1,0.3
			c0.8,0.5,1.2,1.4,1.1,2.2c-0.1,0.5-0.3,1-0.7,1.3L40.4,75c-0.4,0.4-1,0.6-1.6,0.6c-0.6,0-1.1-0.2-1.6-0.6c-0.4-0.4-0.6-1-0.6-1.6
			C36.6,72.8,36.9,72.3,37.3,71.9z M56.6,36.8c0,0-0.1-0.1-0.1-0.1c-0.3-0.3-0.7-0.4-1.1-0.4l-8.8-0.6c0,0-0.1,0-0.1,0
			c-0.7,0-1.3,0.4-1.6,1l-4.5,8c-0.3,0.5-0.8,0.9-1.3,1c-0.6,0.2-1.2,0.1-1.7-0.2c-0.5-0.3-0.9-0.8-1-1.3c-0.2-0.6-0.1-1.2,0.2-1.7
			l6-10.6l11.5-3.3h10.9l13.6,17.3l-4.1,8.7L56.6,36.8z'
        />
      </svg>
    </IconBase>
  );
};

export default PartnerIcon;
