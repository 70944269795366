import React, { FC } from 'react';
import { Table as AntdTable, TableProps } from 'antd';
import styled from '@emotion/styled';
import { theme } from '../styles/theme';

export const { ColumnGroup, Column } = AntdTable;

// TODO: TableProps any needs a Generic type?
//eslint-disable-next-line
export const Table: FC<TableProps<any>> = props => <StyledAntdTable {...props}>{props.children}</StyledAntdTable>;

Table.defaultProps = {};

const StyledAntdTable = styled(AntdTable)`
  .ant-table-tbody .ant-table-row:nth-child(even) {
    background: ${theme.backgroundGrey};
  }
`;
