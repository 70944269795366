import dayjs, { Dayjs } from 'dayjs';

const today = dayjs().startOf('day');
const SUNDAY = 0;
const SATURDAY = 6;

export const isBeforeToday = (date: Dayjs): boolean => {
  const day = date.startOf('day');

  return day.isBefore(today);
};

export const isToday = (date: Dayjs): boolean => {
  const day = date.startOf('day');

  return day.isSame(today);
};

export const isSaturday = (date: Dayjs): boolean => {
  const day = date.startOf('day').day();

  return SATURDAY === day;
};

export const isSunday = (date: Dayjs): boolean => {
  const day = date.startOf('day').day();

  return SUNDAY === day;
};

export const isWeekend = (date: Dayjs): boolean => {
  return isSunday(date) || isSaturday(date);
};
