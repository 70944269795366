import React, { FC } from 'react';
import { Input } from 'antd';
import { SearchProps } from 'antd/es/input';

export const SearchInput: FC<SearchProps> = props => {
  return <Input.Search {...props} />;
};

SearchInput.defaultProps = {
  type: 'search',
  size: 'middle',
  disabled: false,
  enterButton: false,
  loading: false,
};
