import React, { FC, ReactNode } from 'react';
import { List as AntdList } from 'antd';

interface IList {
  bordered?: boolean;
  header?: string | ReactNode;
  footer?: string | ReactNode;
  loading?: boolean;
  itemLayout?: 'horizontal' | 'vertical';
  loadMore?: string | ReactNode;
  split?: boolean;
  column?: number;
  gutter?: number;
  size?: 'default' | 'small' | 'large';
  children?: React.ReactNode;
}

export const List: FC<IList> = props => (
  <AntdList {...props}>
    {props.children}
  </AntdList>
);

List.defaultProps = {
  loading: false,
  bordered: false,
  itemLayout: 'horizontal',
  split: true,
};
