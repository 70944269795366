import React, { FC, useState, useEffect, useCallback } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import deAT from 'dayjs/locale/de-at';
import generatePicker from 'antd/es/date-picker/generatePicker';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';

import { isToday, isBeforeToday, isWeekend, isSaturday, isSunday } from '../helpers/date-helper';
import { IDatePicker } from './types';
import { StyledDatePicker } from './StyledDatePicker';

// Use dayjs instead moment js to reduce bundle size.
const AntdDatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);

const today = dayjs().startOf('day');
const tomorrow = today.add(1, 'day');

// FIXME: For now weekStart is set to SUNDAY (0) - change to MONDAY(1)
dayjs.locale({ ...deAT, weekStart: 0 });

interface ISingleDatePicker extends IDatePicker {
  /**
   * Disable date selection.
   */
  readOnly?: boolean;
  /**
   * Callback function to get the current date.
   */
  onDateChange: (event: Dayjs) => void;
  /**
   * Callback function to see if an error occured.
   */
  onError?: (error: boolean) => void;
  /**
   * Set a date to be preselected.
   */
  inputDate?: string | Dayjs;
  /**
   * Change the placeholder text.
   */
  placeholder?: string;
}

export const SingleDatePicker: FC<ISingleDatePicker> = ({
  size,
  onDateChange,
  onError,
  inputDate,
  placeholder = 'Datum wählen',
  disableBeforeDate,
  readOnly,
  holidays,
  allowToday,
  allowPast,
  allowWeekends,
  allowSaturday,
  allowSunday,
  allowAll,
}) => {
  const isInvalidDate = useCallback(
    (date: Dayjs): boolean => {
      if (allowAll) {
        // Allow all days if prop is set.
        return false;
      }

      if (disableBeforeDate && date.isBefore(disableBeforeDate)) {
        return true;
      }

      if (!allowToday && isToday(date)) {
        return true;
      }

      if (!allowPast && isBeforeToday(date)) {
        return true;
      }

      if (!allowWeekends && isWeekend(date)) {
        if (allowSaturday && isSaturday(date)) {
          return false;
        }

        if (allowSunday && isSunday(date)) {
          return false;
        }

        return true;
      }

      if (holidays && holidays.includes(date.format('YYYY-MM-DD'))) {
        return true;
      }

      return false;
    },
    [allowAll, disableBeforeDate, allowToday, allowPast, allowWeekends, allowSunday, allowSaturday, holidays]
  );

  const getNextValidDate = (date: Dayjs): Dayjs => {
    while (isInvalidDate(date)) {
      date = date.add(1, 'day');
    }
    return date;
  };

  const defaultDate = inputDate
    ? dayjs(inputDate).startOf('day')
    : getNextValidDate(allowToday ? today : tomorrow).startOf('day');
  const [pickedDate, setDate] = useState<Dayjs>(defaultDate);
  const [hasError, setError] = useState<boolean>(isInvalidDate(defaultDate));

  useEffect(() => {
    setError(isInvalidDate(pickedDate));
  }, [pickedDate, isInvalidDate]);

  // Return date / error via callback.
  useEffect(() => {
    onDateChange(pickedDate);
    if (onError) {
      onError(hasError);
    }
  }, [pickedDate, hasError, onDateChange, onError]);

  return (
    <StyledDatePicker>
      <AntdDatePicker
        className={hasError ? 'error' : ''}
        showToday={allowToday && !disableBeforeDate}
        disabledDate={(date): boolean => isInvalidDate(date)}
        defaultValue={defaultDate}
        onChange={(date): void => setDate(date ? date.startOf('day') : defaultDate)}
        disabled={readOnly}
        format={'DD.MM.YYYY'}
        size={size}
        placeholder={placeholder}
      />
    </StyledDatePicker>
  );
};
