export const size = {
  mobile: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1240px',
  desktop: '1600px'
};

export const mediaQueries = {
  mobile: `all and (min-width: ${size.mobile})`,
  tablet: `all and (min-width: ${size.tablet})`,
  laptop: `all and (min-width: ${size.laptop})`,
  laptopL: `all and (min-width: ${size.laptopL})`,
  desktop: `all and (min-width: ${size.desktop})`
};
