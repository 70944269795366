import React, { FC } from 'react';
import { Button as AntdButton, ButtonProps } from 'antd';
import styled from '@emotion/styled';
import { theme } from '../styles/theme';

export const Button: FC<ButtonProps> = props => (
  <StyledAntdButton>
    <AntdButton {...props}>{props.children}</AntdButton>
  </StyledAntdButton>
);

Button.defaultProps = {
  type: 'default',
  size: 'middle',
  loading: false,
  disabled: false,
};

const StyledAntdButton = styled.div`
  .ant-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: @title-family;
    letter-spacing: 0.06em;
    text-transform: uppercase;

    > div {
      margin-right: 0.5em;
      > svg {
        max-height: 1em;
        max-width: 1em;
      }
    }

    &:hover {
      color: ${theme.primaryHoverColor};
      box-shadow: @box-shadow-base;
      background-color: @btn-default-bg;
      border-color: @btn-default-border;
    }

    &.ant-btn-primary {
      &:hover {
        color: ${theme.white};
        background-color: ${theme.primaryHoverColor};
        border-color: @btn-primary-border;
      }
    }
  }

  .ant-btn-background-ghost {
    color: unset;
  }

  .ant-btn-round {
    // FIX FOR ERROR IN ANTD BUTTON.LESS
    &.ant-btn-sm {
      font-size: @btn-font-size-sm;
    }
  }

  .ant-btn-link {
    border: none;
    box-shadow: none;

    &:hover {
      color: ${theme.primaryHoverColor};
      text-decoration: underline !important;
      border: none;
      box-shadow: none;
    }
  }
`;
