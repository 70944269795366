import React, { FC } from 'react';
import { IconBase, IIcon } from './IconBase/IconBase';

const FleetIcon: FC<IIcon> = props => {
  return (
    <IconBase props={props}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 96 96'>
        <path
          d='M88.1,28h-7.3c-0.5,0-0.9,0.2-1.3,0.5L74,33l-0.2-10.5c0-1.1-0.9-2-2-2h-6.5c-0.5,0-0.9,0.2-1.3,0.5l-6,5
            l-0.2-10c0-1.1-0.9-2-2-2h-5.8c-0.5,0-0.9,0.2-1.3,0.5L34.4,26.4H21.8l-6,5.6v4.4H6.4v14.5l10.4,0v1.8H9.2c-3,0-5.5,2.5-5.5,5.5
            s2.5,5.5,5.5,5.5h5.9v-4H9.2c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h7.6v4.7l10.5,0v2.5h-7.5c-3.3,0-5.9,2.6-5.9,5.9
            s2.6,5.9,5.9,5.9h5.2v-4h-5.2c-1,0-1.9-0.9-1.9-1.9c0-1,0.9-1.9,1.9-1.9h7.5v4.9l37.2,0.1l0-10.4l15-16.9v17.1h-8.8
            c-0.8,0-1.6,0.5-1.9,1.3c-0.3,0.8,0,1.7,0.6,2.2l10.4,8.5c0.4,0.3,0.8,0.4,1.3,0.4c0.5,0,1-0.2,1.4-0.5l7-6.6
            c0.4-0.4,0.6-0.9,0.6-1.5V30C90.1,28.9,89.2,28,88.1,28z M66,24.6h3.9l0.3,11.7l-2.5,2l0.2-6.4c0-0.8-0.4-1.6-1.2-1.9
            c-0.7-0.3-1.6-0.2-2.2,0.4L51.8,42.9c-0.1,0.1-0.2,0.2-0.3,0.3h-0.9l0-5.9L66,24.6z M63.5,41.7l-1.8,1.5h-4.5l6.5-6.4L63.5,41.7z
             M48.1,34.2l-0.2,0.2h-4.2l4.5-4.8l-0.1,4.2C48.1,33.9,48.1,34,48.1,34.2z M50.6,18.1h3.2L54,28.8c0,0.2,0,0.3,0.1,0.5l-1.9,1.5
            l0.2-6.4c0-0.8-0.5-1.6-1.2-1.9S49.5,22.4,49,23L38.3,34.3h-1.2l0-5L50.6,18.1z M10.4,46.9v-6.5h9.3v-6.6l3.6-3.4h9.7l0,4.7
            l-5.9,5.5v5H16.8v1.3L10.4,46.9z M20.8,57.3v-7.8h10.5v-7.3l4.2-3.9h3.5c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0h7.3l0,4.9h-0.6
            l-7.2,6.7v5.7H27.3v1.7L20.8,57.3z M31.3,68.8v-9.1h11.6v-8l4.8-4.5h12.8l0,21.6L31.3,68.8z M86.1,65.6L81,70.4l-4.7-3.8h5.1
            c1.1,0,2-0.9,2-2V40.2c0-0.8-0.5-1.6-1.3-1.9c-0.8-0.3-1.7-0.1-2.2,0.5L64.4,56.4l0-10.2L81.6,32h4.5V65.6z'
        />
        <path
          d='M61.6,74.7H30.1c-3.8,0-7,3.1-7,7s3.1,7,7,7h31.5c3.8,0,7-3.1,7-7S65.4,74.7,61.6,74.7z M61.6,84.7H30.1
			c-1.6,0-3-1.3-3-3s1.3-3,3-3h31.5c1.6,0,3,1.3,3,3S63.2,84.7,61.6,84.7z'
        />
      </svg>
    </IconBase>
  );
};

export default FleetIcon;
