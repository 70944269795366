import React, { FC } from 'react';
import { IconBase, IIcon } from './IconBase/IconBase';

export const UserIcon: FC<IIcon> = props => {
  return (
    <IconBase props={props}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 96 96'>
        <path
          d='M85.4,70.3c-6.6-6.8-16.9-11.6-28.4-13.1v-3.7c5.3-2.9,8.8-8.2,8.8-14.2v-7.5h2.3c1.5,0,2.8-0.8,3.4-2.1
		c0.6-1.3,0.4-2.7-0.5-3.8l-2.5-3.1C67.6,12.6,58.7,4.7,48,4.7c-10.7,0-19.6,7.9-20.5,18.1L25,25.8c-0.9,1.1-1.1,2.6-0.5,3.8
		c0.6,1.3,1.9,2.1,3.4,2.1h2.3v7.5c0,6.1,3.5,11.3,8.8,14.2v3.7c-11.5,1.5-21.8,6.2-28.4,13.1L10,70.9v21h76v-21L85.4,70.3z
		 M31.4,24.3l0-0.6c0.4-6.1,4.3-11.2,9.7-13.5v11.4h4V8.9C46.1,8.8,47,8.7,48,8.7c1,0,1.9,0.1,2.8,0.2v12.3h4V10.1
		c5.5,2.4,9.4,7.5,9.7,13.5l0,0.6l2.9,3.5h-39L31.4,24.3z M34.1,39.3v-7.5h27.7v7.5c0,6.9-6.2,12.5-13.9,12.5S34.1,46.2,34.1,39.3z
		 M48,55.8c1.8,0,3.4-0.2,5-0.7v5.6l1.8,0.2c0.5,0,0.9,0.1,1.4,0.2L48,69.3l-8.2-8.2c0.4-0.1,0.9-0.1,1.4-0.2l1.8-0.2v-5.6
		C44.6,55.6,46.2,55.8,48,55.8z M82,87.9H14V72.5c5.1-5,12.5-8.7,21-10.5l11,11v8h4v-8l11-11c8.4,1.8,15.8,5.5,21,10.5V87.9z'
        />
      </svg>
    </IconBase>
  );
};
 export default UserIcon;