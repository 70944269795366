import React, { FC } from 'react';
import { IconBase, IIcon } from './IconBase/IconBase';

const FlexibleIcon: FC<IIcon> = props => {
  return (
    <IconBase props={props}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 96 96'>
        <path
          d='M88.2,52.8v2c0,7.6-6.2,13.8-13.8,13.8s-13.8-6.2-13.8-13.8S66.8,41,74.4,41c3,0,5.2,0.5,6.9,1.4
			c0.6,0.3,1.4,0.3,2,0c0.6-0.4,1-1,1-1.7v-23c0-3.3-2.7-6-6-6H68V4.8h-4v6.9H31.9V4.8h-4v6.9h-9.6c-3.3,0-6,2.7-6,6v2h4v-2
			c0-1.1,0.9-2,2-2h9.6v7.1h4v-7.1H64v7.1h4v-7.1h10.4c1.1,0,2,0.9,2,2v9h-66c-1.1,0-2,0.9-2,2V40c-4.8,3.3-8,8.9-8,15.2
			s3.2,11.9,8,15.2v7.3c0,3.3,2.7,6,6,6h60c3.3,0,6-2.7,6-6v-8c4.8-3.2,7.9-8.6,7.9-14.8v-2H88.2z M8.3,55.2c0-8,6.5-14.5,14.5-14.5
			s14.5,6.5,14.5,14.5s-6.5,14.5-14.5,14.5c-2.5,0-4.9-0.7-7-1.8c-0.3-0.3-0.6-0.5-1-0.6C10.9,64.6,8.3,60.2,8.3,55.2z M80.3,77.7
			c0,1.1-0.9,2-2,2h-60c-1.1,0-2-0.9-2-2v-5.2c2,0.8,4.2,1.2,6.5,1.2c10.2,0,18.5-8.3,18.5-18.5S33,36.7,22.8,36.7
			c-2.3,0-4.5,0.4-6.5,1.2v-7.2h64v7.1c-1.7-0.5-3.7-0.7-5.9-0.7c-9.1,0-16.6,6.8-17.7,15.6h-7.4l4.3-4.5l-2.9-2.8l-7.6,8
			c-0.8,0.8-0.7,2.1,0.1,2.8l8,7.6l2.8-2.9l-4.6-4.3h7.3c0.9,9,8.5,16,17.7,16c2.1,0,4-0.4,5.9-1V77.7z'
        />
        <path
          d='M66.9,48.6l-2.7,3l8.8,7.9c0.4,0.3,0.9,0.5,1.3,0.5c0.5,0,1-0.2,1.4-0.5l15.5-14.6L88.6,42L74.4,55.3
			L66.9,48.6z'
        />
        <polygon
          points='14.3,60.9 17.3,63.7 22.9,57.5 29.1,63.2 31.8,60.2 25.6,54.6 31.3,48.4 28.4,45.7 22.7,51.8 
			16.5,46.2 13.8,49.1 20,54.8 '
        />
      </svg>
    </IconBase>
  );
};

export default FlexibleIcon;
