import React, { FC } from 'react';
import styled from '@emotion/styled';

export interface IIcon {
  fill?: string;
  size?: string;
  hoverFill?: string;
  transition?: string;
  className?: string;
}

export interface IIconBase {
  props: IIcon;
  children: React.ReactNode;
}

export const IconBase: FC<IIconBase> = ({ props, children }) => {
  return (
    <StyledIcon
      fill={props.fill ? props.fill : 'currentColor'}
      size={props.size ? props.size : '1.5rem'}
      hoverFill={props.hoverFill ? props.hoverFill : undefined}
      transition={props.transition ? props.transition : '300ms ease-in-out'}
      className={props.className ? props.className : undefined}
    >
      {children}
    </StyledIcon>
  );
};

const StyledIcon = styled.div<IIcon>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(p): string | undefined => p.size};
  width: ${(p): string | undefined => p.size};
  margin: 0;
  padding: 0;

  > svg {
    height: ${(p): string | undefined => p.size};
    width: ${(p): string | undefined => p.size};
    fill: ${(p): string | undefined => p.fill};
    transition: ${(p): string | undefined => p.transition ? `fill ${p.transition}` : undefined};

    > path {
      fill: ${(p): string | undefined => p.fill};
      transition: ${(p): string | undefined => p.transition ? `fill ${p.transition}` : undefined};
    }
  }

  &:hover {
    > svg {
      fill: ${(p): string | undefined => p.hoverFill};
  
      > path {
        fill: ${(p): string | undefined => p.hoverFill};
      }
    }
  }
`;

export default IconBase;
