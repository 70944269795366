import React from 'react';
import styled from '@emotion/styled';
import { Layout } from 'antd';
import { LayoutProps } from 'antd/es/layout';

export const Content: React.FC<LayoutProps> = (props) => {
  return (
    <StyledContent {...props} />
  );
};

const StyledContent = styled(Layout.Content)`
  background-color: #ffffff;
  padding: 0.3em 1.2em;
`;
