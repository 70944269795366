import React from 'react';
import styled from '@emotion/styled';
import { Layout } from 'antd';
import { LayoutProps } from 'antd/es/layout';
import Image from 'next/legacy/image';

interface IMenuHeaderProps {
  collapsed: boolean;
}

export const MainMenuHeader: React.FC<IMenuHeaderProps & LayoutProps> = ({ collapsed }) => {
  return collapsed ? (
    <HeaderCollapsed>
      <Image alt="DIGANDO" src="/static/images/logo-small.svg" width={48} height={48}/>
    </HeaderCollapsed>
  ) : (
    <Header>
      <Image alt="DIGANDO" src="/static/images/logo.svg" width={128} height={14} />
    </Header>
  );
};

const Header = styled(Layout.Header)`
  background-color: rgba(0,0,0,0);
  padding: 0 10%;
  text-align: center;

  > img {
    width: 80%;
    height: auto;
  }
`;

const HeaderCollapsed = styled(Layout.Header)`
  background-color: rgba(0,0,0,0);
  padding: 0 16px;
  text-align: center;
  display: flex;

  > img {
    width: 48px;
    height: auto;
  }
`;
