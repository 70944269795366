import React, { FC } from 'react';
import { Form as AntdForm, FormProps } from 'antd';

interface IFormProps extends FormProps {
  children?: React.ReactNode
}

export const Form: FC<IFormProps> = props => <AntdForm {...props}>{props.children}</AntdForm>;

Form.defaultProps = {};
