import React, { FC } from 'react';
import { IconBase, IIcon } from './IconBase/IconBase';

const LiveIcon: FC<IIcon> = props => {
  return (
    <IconBase props={props}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 96 96'>
        <path
          d='M88.2,52.8v2c0,7.6-6.2,13.8-13.8,13.8s-13.8-6.2-13.8-13.8S66.8,41,74.4,41c3,0,5.2,0.5,6.9,1.4
			c0.6,0.3,1.4,0.3,2,0c0.6-0.4,1-1,1-1.7v-23c0-3.3-2.7-6-6-6H68V4.8h-4v6.9H31.9V4.8h-4v6.9h-9.6c-3.3,0-6,2.7-6,6v2h4v-2
			c0-1.1,0.9-2,2-2h9.6v7.1h4v-7.1H64v7.1h4v-7.1h10.4c1.1,0,2,0.9,2,2v9h-68v51c0,3.3,2.7,6,6,6h60c3.3,0,6-2.7,6-6v-8
			c4.8-3.2,7.9-8.6,7.9-14.8v-2H88.2z M80.3,77.7c0,1.1-0.9,2-2,2h-60c-1.1,0-2-0.9-2-2v-47h64v7.1c-1.7-0.5-3.7-0.7-5.9-0.7
			c-8.7,0-16,6.3-17.5,14.6h-8.6v-12h-4v12h-12v-12h-4v12h-7v4h7v13h4v-13h12v13h4v-13h8.3c0.4,9.4,8.2,17,17.8,17
			c2.1,0,4-0.4,5.9-1V77.7z'
        />
        <path
          d='M66.9,48.6l-2.7,3l8.8,7.9c0.4,0.3,0.9,0.5,1.3,0.5c0.5,0,1-0.2,1.4-0.5l15.5-14.6L88.6,42L74.4,55.3
			L66.9,48.6z'
        />
      </svg>
    </IconBase>
  );
};
export default LiveIcon;
