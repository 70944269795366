import React, { FC } from 'react';
import { IconBase, IIcon } from './IconBase/IconBase';

const FleetmanagerIcon: FC<IIcon> = props => {
  return (
    <IconBase props={props}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 96 96'>
        <path
          d='M87.1,13.7h-8.8c-0.5,0-1,0.2-1.4,0.6L55.3,35.7h-7.7l1.5-13.2c1.3-0.3,2.6-1,3.6-2c1.2-1.3,1.8-3.1,1.7-4.9
			c-0.1-1.8-0.9-3.5-2.2-4.7c-2.8-2.5-7-2.3-9.5,0.4c-2.5,2.7-2.3,7,0.5,9.5h0c0.6,0.5,1.3,1,2,1.3l-1.5,13.6h-5.5l-6.5-11.9
			c1-1,1.6-2.3,1.7-3.7c0.5-3.7-2.2-7.1-5.9-7.5c-3.7-0.5-7.1,2.1-7.6,5.8s2.2,7.1,5.9,7.5c0.3,0,0.6,0.1,0.9,0.1
			c0.5,0,1-0.1,1.5-0.2l6.5,11.7L30.9,41l-11.5-5.5c0.2-1.3-0.1-2.8-0.8-4c-1.8-3.3-5.9-4.5-9.2-2.7c-3.3,1.8-4.5,5.9-2.7,9.1
			c0.9,1.6,2.3,2.7,4,3.2c0.6,0.2,1.3,0.3,1.9,0.3c1.1,0,2.2-0.3,3.3-0.8c0.7-0.4,1.3-0.9,1.8-1.5l10.4,4.9v6.6h-14v21l44.1,0.1
			l0-13.6c0,0,0,0,0,0l18.9-21.2v25.7h-11c-0.8,0-1.6,0.5-1.9,1.3c-0.3,0.8-0.1,1.6,0.5,2.2l12.6,11.4c0.4,0.3,0.9,0.5,1.3,0.5
			c0.5,0,1.1-0.2,1.5-0.6l8.4-9.2c0.3-0.4,0.5-0.9,0.5-1.4V15.7C89.1,14.6,88.2,13.7,87.1,13.7z M45.6,14c1-1.1,2.8-1.2,3.9-0.1
			c0.5,0.5,0.9,1.2,0.9,1.9c0,0.7-0.2,1.4-0.7,2c-1,1.1-2.8,1.2-3.9,0.1C44.7,16.9,44.6,15.1,45.6,14z M14,37.1L14,37.1
			c-0.7,0.4-1.4,0.4-2.1,0.2c-0.7-0.2-1.3-0.7-1.6-1.3c-0.7-1.3-0.2-3,1.1-3.7c0.4-0.2,0.9-0.3,1.3-0.3c1,0,1.9,0.5,2.4,1.4
			C15.8,34.7,15.3,36.4,14,37.1z M23.9,18.9c0.2-1.5,1.6-2.6,3.1-2.4c1.5,0.2,2.6,1.6,2.4,3.1c-0.1,0.7-0.5,1.4-1,1.8
			c-0.6,0.4-1.3,0.6-2.1,0.6C24.8,21.7,23.7,20.4,23.9,18.9z M18.1,67.7v-13h14v-9.4l6-5.6h16l0.1,28.1L18.1,67.7z M85.1,66.1
			l-6.5,7.1l-7.3-6.6h7.8c1.1,0,2-0.9,2-2v-33c0-0.8-0.5-1.6-1.3-1.9c-0.8-0.3-1.7-0.1-2.2,0.5L58.1,52.2l0-13.7l21.1-20.8h5.9V66.1
			z'
        />
        <path
          d='M55.1,73.7h-38c-4.4,0-8,3.6-8,8s3.6,8,8,8h38c4.4,0,8-3.6,8-8S59.5,73.7,55.1,73.7z M55.1,85.7h-38
			c-2.2,0-4-1.8-4-4s1.8-4,4-4h38c2.2,0,4,1.8,4,4S57.3,85.7,55.1,85.7z'
        />
      </svg>
    </IconBase>
  );
};

export default FleetmanagerIcon;
