import React, { FC } from 'react';
import { IconBase, IIcon } from './IconBase/IconBase';

export const SecondUserIcon: FC<IIcon> = props => {
  return (
    <IconBase props={props}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 96 96'>
        <path
          d='M65.6,59.4h-4.4V55c0.3-0.2,0.5-0.4,0.8-0.7c2.5-2.5,4.1-5.5,4.9-8.8c0.1,0,0.3,0,0.4,0c3.8,0,6.9-3.1,6.9-6.9
		c0-3.8-3-6.8-6.8-6.9v-2.1c0-0.5-0.1-1.4-0.2-2.2c0.3-1.4,0.6-3.4,0.6-6.5c0-4-2.1-7-6.2-8.8c-3.2-1.4-7.5-2.1-12.7-2.1h-0.1v0
		c-6.2,0-13.7,3.2-17,7.2c-1.4,1.8-2,3.6-1.7,5.3c0.2,0.9,0.4,1.7,0.6,2.2c0,0.1-0.1,0.2-0.1,0.3c-0.4,1.5-0.5,3-0.5,4.5v2.1
		c-3.7,0.1-6.8,3.1-6.8,6.9c0,3.8,3.1,6.9,6.9,6.9c0.2,0,0.3,0,0.4,0c0.8,3.3,2.5,6.4,4.9,8.8c0.3,0.3,0.7,0.6,1,0.9v4.2H32
		c-10.4,0-18.8,8.4-18.8,18.8V91h71.2V78.2C84.4,67.9,76,59.4,65.6,59.4z M67.4,41.1v-5.8c1.8,0.1,3.2,1.5,3.2,3.3
		c0,1.8-1.4,3.2-3.2,3.3C67.4,41.7,67.4,41.4,67.4,41.1z M26.9,38.6c0-1.8,1.4-3.2,3.2-3.3v5.8c0,0.3,0,0.5,0,0.8
		C28.4,41.9,26.9,40.4,26.9,38.6z M34.6,19.6c1.3-1.5,3.5-3,6.1-4.1c2.8-1.2,5.7-1.8,8.2-1.8v0c4.7,0,8.6,0.6,11.3,1.8
		c1.4,0.6,2.4,1.4,3.1,2.2c0.7,0.9,1,2,1,3.3c0,4.2-0.5,6-1.1,7.2c-2.4-1.1-6-2.7-10.3-3.4c-1.3-0.3-2.7-0.4-4.1-0.4
		c-2.1,0-4.1-0.2-5.8-0.4c-1.4-0.2-2.7-0.5-4.1-0.9c-1.3-0.3-2.5-0.8-3.7-1.3L33.7,21C33.9,20.5,34.2,20,34.6,19.6z M33.7,41.1v-0.2
		c0-0.5,0-1.1,0-1.7c0-0.3,0-0.6,0-0.9c0-0.3,0-0.6,0-0.9c0-0.6,0-1.1,0-1.6v-6.2c0-1.2,0.1-2.4,0.4-3.6c0-0.2,0.1-0.3,0.1-0.4
		l0.1-0.1c1.5,0.5,3.9,1.3,7.1,1.8c2.1,0.4,4.6,0.6,7.3,0.6c1.2,0,2.4,0.1,3.5,0.3c3.2,0.6,6.1,1.9,8.2,2.9c1.3,0.6,2.4,1.1,3.4,1.3
		v3.3c0,0.5,0,1,0,1.6c0,0.3,0,0.6,0,0.9c0,0.3,0,0.6,0,0.9c0,0.6,0,1.2,0,1.7v0.3c0,8.3-6.8,15.1-15.1,15.1
		C40.4,56.2,33.7,49.4,33.7,41.1z M57.2,57.8v2.6l-8.3,9.7l-8.3-9.7v-2.5c2.5,1.2,5.3,1.9,8.1,1.9c0,0,0,0,0,0
		C51.7,59.8,54.6,59.1,57.2,57.8z M80.8,87.4H16.8v-9.2C16.8,69.8,23.6,63,32,63h5.6l11.3,13.2L60.2,63h5.4
		c8.4,0,15.2,6.8,15.2,15.2V87.4z'
        />
      </svg>
    </IconBase>
  );
};
export default SecondUserIcon;
