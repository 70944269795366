import React, { FC } from 'react';
import { IconBase, IIcon } from './IconBase/IconBase';

const BookNowIcon: FC<IIcon> = props => {
  return (
    <IconBase props={props}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 96 96'>
        <path d='M38,49.6l-2.7,3l10.1,9.1c0.4,0.3,0.9,0.5,1.3,0.5c0.5,0,1-0.2,1.4-0.5l18-16.9l-2.7-2.9L46.7,57.5L38,49.6z' />
        <path
          d='M47.7,34.9c-11.4,0-20.6,9.2-20.6,20.6c0,11.4,9.2,20.6,20.6,20.6s20.6-9.2,20.6-20.6v-2h-4v2
			c0,9.2-7.4,16.6-16.6,16.6c-9.2,0-16.6-7.4-16.6-16.6s7.4-16.6,16.6-16.6c2.8,0,5.6,0.7,8.1,2.1l1.7,1l1.9-3.5l-1.7-1
			C54.7,35.8,51.2,34.9,47.7,34.9z'
        />
        <path
          d='M78.2,11.7H68V4.8h-4v6.9H31.9V4.8h-4v6.9H17.6c-2.6,0-6.4,1.7-6.4,6.3v59.3h4V30.6h65.3v49.1
			c0,1.9-1.5,2.2-2.4,2.3H20v4h58.2c2.6,0,6.4-1.7,6.4-6.3V17.9C84.6,15.4,82.9,11.7,78.2,11.7z M15.3,26.6v-8.7
			c0-1.9,1.5-2.2,2.4-2.3h10.2v7.1h4v-7.1H64v7.1h4v-7.1h10.3c2,0,2.3,1.4,2.4,2.3v8.7H15.3z'
        />
        <path d='M15.4,80.1c-1,0-1.7,0.8-1.7,1.7c0,0.9,0.8,1.7,1.7,1.7s1.7-0.8,1.7-1.7C17.1,80.8,16.3,80.1,15.4,80.1z' />
      </svg>
    </IconBase>
  );
};

export default BookNowIcon;
