import React, { FC, useCallback, useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import deAT from 'dayjs/locale/de-at';
import generateRangePicker from 'antd/lib/date-picker/generatePicker/generateRangePicker';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';

import { isBeforeToday, isSaturday, isSunday, isToday, isWeekend } from '../helpers/date-helper';
import { IDatePicker } from './types';
import { StyledDatePicker } from './StyledDatePicker';
// Use dayjs instead moment js to reduce bundle size.
const AntdRangePicker = generateRangePicker<Dayjs>(dayjsGenerateConfig);

const today = dayjs().startOf('day');
const tomorrow = today.add(1, 'day');

// FIXME: For now weekStart is set to SUNDAY (0) - change to MONDAY(1)
dayjs.locale({ ...deAT, weekStart: 0 });

interface IDateRangePicker extends IDatePicker {
  /**
   * Disable date selection.
   */
  readOnly?: [boolean, boolean];
  /**
   * Callback function to get the current start date.
   */
  onStartDateChange: (date: Dayjs) => void;
  /**
   * Callback function to get the current end date.
   */
  onEndDateChange: (date: Dayjs) => void;
  /**
   * Callback function to handle end date error change.
   */
  onStartError?: (error: boolean) => void;
  /**
   * Callback function to handle end date error change.
   */
  onEndError?: (error: boolean) => void;
  /**
   * Set a START date to be preselected.
   */
  startDate?: string | Dayjs;
  /**
   * Set a END date to be preselected.
   */
  endDate?: string | Dayjs;
  /**
   * Change placeholder text [startPlaceholder, endPlaceholder]
   */
  placeholder?: [string, string];
}

export const DateRangePicker: FC<IDateRangePicker> = ({
  size,
  placeholder = ['Startdatum', 'Enddatum'],
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  onStartError,
  onEndError,
  disableBeforeDate,
  holidays,
  readOnly = [false, false],
  allowToday,
  allowPast,
  allowWeekends,
  allowSaturday,
  allowSunday,
  allowAll,
}) => {
  const isInvalidDate = useCallback(
    (date: Dayjs): boolean => {
      if (allowAll) {
        return false;
      }

      if (disableBeforeDate && date.isBefore(disableBeforeDate)) {
        return true;
      }

      if (!allowToday && isToday(date)) {
        return true;
      }

      if (!allowPast && isBeforeToday(date)) {
        return true;
      }

      if (!allowWeekends && isWeekend(date)) {
        if (allowSaturday && isSaturday(date)) {
          return false;
        }

        if (allowSunday && isSunday(date)) {
          return false;
        }

        return true;
      }

      if (holidays && holidays.includes(date.format('YYYY-MM-DD'))) {
        return true;
      }

      return false;
    },
    [allowAll, disableBeforeDate, allowToday, allowPast, allowWeekends, allowSunday, allowSaturday, holidays],
  );

  const getNextValidDate = (date: Dayjs): Dayjs => {
    while (isInvalidDate(date)) {
      date = date.add(1, 'day');
    }
    return date;
  };

  const defaultStartDate = startDate
    ? dayjs(startDate).startOf('day')
    : getNextValidDate(allowToday ? today : tomorrow).startOf('day');
  const defaultEndDate = endDate
    ? dayjs(endDate).startOf('day')
    : getNextValidDate(allowToday ? today : tomorrow).startOf('day');

  const [pickedStartDate, setStartDate] = useState<Dayjs>(defaultStartDate);
  const [pickedEndDate, setEndDate] = useState<Dayjs>(defaultEndDate);

  const [hasStartError, setStartError] = useState<boolean>(isInvalidDate(defaultStartDate));
  const [hasEndError, setEndError] = useState<boolean>(isInvalidDate(defaultEndDate));

  // Check for valid dates.
  useEffect(() => {
    setStartError(isInvalidDate(pickedStartDate));
    setEndError(isInvalidDate(pickedEndDate));
  }, [pickedStartDate, pickedEndDate, isInvalidDate]);

  // Return dates / errors via callback.
  useEffect(() => {
    onStartDateChange(pickedStartDate);
    onEndDateChange(pickedEndDate);

    if (onStartError) {
      onStartError(hasStartError);
    }
    if (onEndError) {
      onEndError(hasEndError);
    }
  }, [pickedStartDate, pickedEndDate, hasStartError, hasEndError, onStartError, onEndError, onStartDateChange, onEndDateChange]);

  return (
    <StyledDatePicker>
      <AntdRangePicker
        className={hasStartError || hasEndError ? 'error' : ''}
        disabledDate={(date): boolean => isInvalidDate(date)}
        defaultValue={[defaultStartDate, defaultEndDate]}
        disabled={readOnly}
        onChange={(dates): void => {
          setStartDate(dates && dates[0] ? dates[0].startOf('day') : defaultStartDate);
          setEndDate(dates && dates[1] ? dates[1].startOf('day') : defaultEndDate);
        }}
        format={'DD.MM.YYYY'}
        size={size}
        separator={''}
        placeholder={placeholder}
      />
    </StyledDatePicker>
  );
};
